import React from "react";
import Layout from "../../components/shared/layout";
import PostPreviews from "../../components/post-previews";

const Blog = () => {
  return (
    <Layout pageTitle="Blog">
      <PostPreviews />
    </Layout>
  );
};

export default Blog;